import React from "react";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import SEO from "../components/seo";

const TermsOfUse = ({ data }) => (
    
  <Layout>
    <Helmet 
      meta={[
        { name: 'description', content: 'Clearinghouse Navigator Terms of Use' },
        { name: 'keywords', content: 'Clearinghouse Navigator,  Terms of Use' },
      ]}
    />  
    <SEO title="Terms of Use" />
      <div className="container">
        <div className="row">
          <div className="col-md-auto py-5">
            <h2  dangerouslySetInnerHTML={{ __html: data.allNodePage.edges[3].node.title }} ></h2>
            <div className="text-left" dangerouslySetInnerHTML={{ __html: data.allNodePage.edges[3].node.body.value }} />
          </div>
        </div>
      </div>
  </Layout>
)

export const query = graphql` 
  query {
    allNodePage {  
      edges { 
        node { 
          id title body { value format processed summary }
        } 
      } 
    }  
  } `

export default TermsOfUse
